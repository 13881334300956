<template>
  <v-container fluid>
    <head-title
      text="Cuentas Contables"
      :to="{ name: 'accounting-register-buy-home' }"
    ></head-title>
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="searchText"
          label="Buscar"
          placeholder="Buscar por Nombre o Código"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="filter"
          :items="[1, 2, 3, 4]"
          label="Filtrar por nivel"
          clearable
        ></v-select>
      </v-col>
      <v-col cols="12" md="3" class="text-end">
        <v-btn class="mx-2" fab dark small color="primary" @click="createProvider()">
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="accounts"
      :loading="loading"
      hide-default-footer
      disable-sort
      disable-pagination
      class="elevation-1"
    >
    </v-data-table>
    <new-account
      :dialog="dialogForm"
      @close="saved()"
    ></new-account>
  </v-container>
</template>

<script>
import request from "@/services/requests";

export default {
  name: "ProviderList",
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    NewAccount: () => import("@/components/accounting/accounts/Form.vue"),
  },
  data() {
    return {
      filter: undefined,
      searchText: "",
      accounts: [],
      loading: false,
      accountsId: 0,
      options: 0,
      dialogForm: false,
      headers: [
        { text: "Cuenta", value: "name" },
        { text: "Código", value: "code" },
        { text: "Nivel", value: "level" },
      ],
    };
  },
  mounted() {
    this.getAccounts();
  },
  methods: {
    getAccounts() {
      this.loading = true;
      let uri =
        "/api/accounting/classification/?search=" +
        this.searchText;
      if (this.filter != undefined) {
        uri += "&level=" + this.filter;
      }
      request.get(uri).then((res) => {
        this.accounts = res.data;
      });
      this.loading = false;
    },
    createProvider() {
      this.dialogForm = true;
    },
    saved() {
      this.dialogForm = false;
      this.searchText = "";
      this.filter = undefined;
    },
  },
  watch: {
    searchText: function () {
      if (this.searchText.length > 2 || this.searchText.length == 0) {
        this.getAccounts();
      }
    },
    filter: function () {
      this.getAccounts();
    },
  },
};
</script>
